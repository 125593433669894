import React from "react";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Footer from "./components/Footer/Footer";
import { Wrapper } from "./styles"

function App() {
  return (
      <Wrapper id={'wrapper'}>
          <Wrapper.Header>
              <Header/>
          </Wrapper.Header>
          <Wrapper.Main>
              <Main/>
          </Wrapper.Main>
          <Wrapper.Footer>
              <Footer/>
          </Wrapper.Footer>
      </Wrapper>
  );
}

export default App;
