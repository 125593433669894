import styled from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10vw;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Name = styled.div`
  font-size: 2.2vw;
  min-font-size: 20px;
  font-weight: 500;
  color: #dee1dd;
`;

export const RightBlock = styled.div`
display: flex;
`;

export const Contacts = styled.div`
display: flex;
`;

Contacts.Image = styled.div`
  width: 24px;
  height: 24px;
  mask-size: cover !important;
  mask: url("${({ image }) => image}") center no-repeat;
  //background-color: #dee1dd;
  margin: 20px;
  background: #b4b4b4;

  :hover{
    background-color: white;
  }
`;
