import React, {useEffect} from "react";
import {Wrapper, ImagePlateContainer, Image} from "./styles"
import {lexusCarusel} from "./helpers/caruseles"
import {getImages} from "./helpers/imagesPlate"
import CustomSlider from "../common/CustomSlider/CustomSlider";
import {animation} from '../../helpers/animation';
import  './style.css'

function Main() {
    useEffect(() => {
        animation();
    }, []);

    return (
        <Wrapper>
            <CustomSlider settings={lexusCarusel.settings} images={lexusCarusel.images} />
            <ImagePlateContainer>
                {getImages().map((element) => (
                    <Image
                        key={element.title}
                        className={'_anim-items'}
                        // className={`${!element.isLoading ? "skeleton-image" : "skeleton-image_loading"}`}
                        src={element.image}
                        onLoad={() => {
                            // console.log('element.title__isLoading', element.title)
                            // Встановлюємо флаг isLoading, якщо зображення завантажене
                            element.isLoading = false;
                        }}
                    />
                ))}
            </ImagePlateContainer>
        </Wrapper>
    );
}

export default Main;
