import React from "react";
import PropTypes from 'prop-types';
import {Wrapper, Image, IconNext, IconPrevios} from "./styles"
import Slider from "react-slick";

function CustomSlider(props) {
    const { settings, images } = props;
    return (
        <Wrapper>
                <Slider {...settings}
                            nextArrow={<IconNext/>}
                        prevArrow={<IconPrevios/>}
                    >
                    { images.map(image =>
                    <Image src={image.image} key={image.title}/>
                )}

                </Slider>
        </Wrapper>
    );
}

CustomSlider.defaultProps = {
    settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
}

CustomSlider.propTypes = {
    settings: PropTypes.object,
    images: PropTypes.array,
}



export default CustomSlider;