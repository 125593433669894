export const animation =()=> {
    const animItems = document.querySelectorAll('._anim-items');


    if (animItems.length > 0) {

        document.querySelector('#wrapper').addEventListener('scroll', animOnScroll);

        function animOnScroll() {
            for (let index = 0; index < animItems.length; index++) {
                const animItem = animItems[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 90;

                let animItemPoint = window.innerHeight - animItemHeight / animStart;
                if (animItemHeight > window.innerHeight) {
                    animItemPoint = window.innerHeight - window.innerHeight / animStart;
                }
// eslint-disable-next-line no-restricted-globals
                if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight)) {
                    animItem.classList.add('anim');
                } else {
                    animItem.classList.remove('anim');
                }
            }
        }

        function offset(el) {
            const rect = el.getBoundingClientRect(),
                // eslint-disable-next-line no-restricted-globals
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                // eslint-disable-next-line no-restricted-globals
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
        }

        animOnScroll();
    }
}