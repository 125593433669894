import React from "react";
import { Wrapper } from "./styles"

function Footer() {
    return (
        <Wrapper>
            © 2023 Berdova Kateryna
        </Wrapper>
    );
}

export default Footer;
