import React from "react";
import { Wrapper, Name, RightBlock, Contacts } from "./styles";
import instagramIcon from '../../assets/instagram.svg'
import mail from '../../assets/mail.svg'
import behance from '../../assets/behance.svg'

function Header() {
    return (
        <Wrapper>
            <Name>Retocher Katrin</Name>
            <RightBlock>
                <Contacts>
                    <a href="https://instagram.com/retoucher_katrin/" target="_blank" rel="noreferrer">
                    <Contacts.Image image={instagramIcon}/>
                    </a>
                    <a href="mailto:berdovakatya@gmail.com">
                    <Contacts.Image image={mail}/>
                    </a>
                    <a href="https://www.behance.net/retoucher_katrin?fbclid=PAAaZV-5uwc11TbseGAialprDleaj6rimGIkNdQN8zVAPFTSc0D6eIT5iDFBQ" target="_blank" rel="noreferrer">
                    <Contacts.Image image={behance}/>
                    </a>
                </Contacts>
            </RightBlock>
        </Wrapper>
    );
}

export default Header;
