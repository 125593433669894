import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative; /* Добавьте это свойство */

  /* Остальные стили оставьте без изменений */
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    z-index: 9999;
    position: relative;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    z-index: 9999;
    position: relative;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #CDD4E2;
    z-index: 9999;
    position: fixed;
  }
`;

Wrapper.Header = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  position: sticky;
  z-index: 10;
  top: 0;
  //border-bottom: rgba(255,255,255, 0.3) 1px solid;
`;

Wrapper.Main = styled.div`
  display: flex;
  width: 100%;
  //height: 100%;
  position: relative;
  //background-color: aqua;
`;
Wrapper.Footer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  //background-color: black;
  bottom: 0;
`;
