import styled, {keyframes} from 'styled-components';

const nameAnimation = keyframes` 
  0% {opacity: 0; transform: scale(0) } 
  100% {opacity: 1; transform: scale(1) }
`


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 4rem ;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`;

export const ImagePlateContainer = styled.div`
  columns: 3 200px;
  column-gap: 2vw;
  width: 100%;
  margin-top: 2vw;
;
`;
export const ImageContainer = styled.div`
  display: flex;
  height: fit-content;
  width: 32%;
`;

export const Image = styled.img`
  object-fit: contain;
  height: fit-content;
  width: 100%;
  margin-bottom:  2vw;

  transform: scale(0.5);
  transition: all 0.8s ease 0s;
`;



