export const getImages= () =>{
    const imagesPack=[];

    for(let i = 1 ; i < 13; i++){
        let img = document.createElement('img');

        img.src = process.env.PUBLIC_URL + `/plate/${i}.jpg`;
        imagesPack.push({
            title: `image-${i}`,
            image: process.env.PUBLIC_URL + `/plate/${i}.jpg`,
            isLoading: true,
        })
        // let poll = setInterval(function () {
        //     if (img.naturalWidth) {
        //         clearInterval(poll);
        //         console.log('img', img);
        //         console.log(img.naturalWidth, img.naturalHeight);
        //     }
        // }, 10);
    }

    for(let i = 1 ; i < 42; i++){
        let img = document.createElement('img');

        img.src = process.env.PUBLIC_URL + `/plate/image (${i}).jpg`;
        imagesPack.push({
            title: `image${i}`,
            image: process.env.PUBLIC_URL + `/plate/image (${i}).jpg`,
            isLoading: true,
        })
        // let poll = setInterval(function () {
        //     if (img.naturalWidth) {
        //         clearInterval(poll);
        //         console.log('img', img);
        //         console.log(img.naturalWidth, img.naturalHeight);
        //     }
        // }, 10);
    }
    return imagesPack;
}
