import styled from 'styled-components';
import arrow from '../../../assets/Arrow2.svg';

export const Wrapper = styled.div`
  width: 100%;
  //height: 100vh;
  box-sizing: border-box;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  background-size:cover;
`;

export const IconNext = styled.svg`
  height: 5vw;
  width: 5vw;
  mask: url("${arrow}") no-repeat center;
  mask-size: cover;
  background: #787878;
  
  :hover{
    background-color: #d2d2d2;
  }
`;

export const IconPrevios = styled.svg`
  height: 5vw;
  width: 5vw;
  mask: url("${arrow}") no-repeat center;
  mask-size: cover;
  background: #787878;
  transform: rotate(180deg);
  top: calc(50% - 2.5vw);
  
  :hover{
    background-color: #d2d2d2;
  }
`;
