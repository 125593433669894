import lexus1 from "../../../assets/PhotosCarusel/Lexus1.jpg"
import lexus2 from "../../../assets/PhotosCarusel/Lexus2.jpg"
import lexus3 from "../../../assets/PhotosCarusel/Lexus3.jpg"
import mersedes1 from "../../../assets/PhotosCarusel/Mersedes1.jpg"
import mersedes2 from "../../../assets/PhotosCarusel/Mersedes2.jpg"
import mersedes3 from "../../../assets/PhotosCarusel/Mersedes3.jpg"

export const lexusCarusel={
    settings:{
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false,
    },
    images:[
        {
        title: 'Lexus1',
        image: lexus1
        },
        {
            title: 'Lexus2',
            image: lexus2
        },
        {
            title: 'Lexus3',
            image: lexus3
        },
        {
            title: 'mersedes1',
            image: mersedes1
        },
        {
            title: 'mersedes2',
            image: mersedes2
        },
        {
            title: 'mersedes3',
            image: mersedes3
        },
    ]
}